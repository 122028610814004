<template>
  <div class="h-full">
    <el-form
      ref="form"
      :model="formData"
      class="h-full"
    >
      <el-table
        :data="formData.tableData"
        row-key="uid"
        class="w-full h-full overflow-auto"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column
          label="视频"
          width="150"
        >
          <template slot-scope="scope">
            <el-form-item
              label=""
              :prop="'tableData.' + scope.$index + '.status'"
              :rules="videoStatusRules"
            >
              <input
                v-model="scope.row.status"
                type="hidden"
              />
              <div class="flex items-center">
                <span :class="'text-[' + statusIconMap[scope.row.status].color + ']'">
                  <i
                    :class="statusIconMap[scope.row.status].icon"
                    :style="{
                      color: statusIconMap[scope.row.status].color,
                    }"
                  ></i>
                  <el-button
                    type="text"
                    v-if="scope.row.status == 'error'"
                    @click="emitUploadFile(scope.row)"
                    >重新上传</el-button
                  >
                </span>
                <div class="w-24 h-24">
                  <video
                    :src="scope.row.blobUrl"
                    class="w-full h-full"
                  ></video>
                </div>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="视频标题"
        >
          <template slot-scope="scope">
            <el-form-item
              label=""
              :prop="'tableData.' + scope.$index + '.videoTitle'"
              :rules="[{ validator: validateTitle, trigger: 'blur' }]"
            >
              <el-input
                v-model="scope.row.videoTitle"
                placeholder=""
                @change="emitData"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="province"
          label="上传人"
          width="120"
        >
          <template>
            <div>
              {{ userName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
/**
 * 校验视频上传状态
 */
const validateVideoStatus = (rule, value, callback) => {
  if (value === 'success') {
    callback();
  } else {
    callback(new Error('视频上传中或上传失败'));
  }
};
// 校验标题
const validateTitle = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写标题'));
  } else {
    if (value.length > 100) {
      callback(new Error('视频标题过长,不能超过100个字符'));
    }
    callback();
  }
};
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        tableData: [],
      },
      selectList: [],
      videoStatusRules: [
        {
          validator: validateVideoStatus,
        },
      ],
      validateTitle,
      statusIconMap: {
        uploading: {
          icon: 'el-icon-loading',
          color: '#909399',
        },
        success: {
          icon: 'el-icon-check',
          color: '#67C23A',
        },
        error: {
          icon: 'el-icon-close',
          color: '#F56C6C',
        },
      },
    };
  },
  computed: {
    userName() {
      return this.$store.state.user.userInfo.data.name;
    },
  },
  methods: {
    /**
     * 选择数据
     */
    handleSelectionChange(val) {
      this.selectList = val.map((v) => v.uid);
    },
    /**
     * 删除
     */
    handleClick(index) {
      this.formData.tableData.splice(index, 1);
      this.emitData();
    },
    /**
     * 重新上传
     */
    emitUploadFile(file) {
      this.$emit('uploadFile', {
        ...file,
        status: 'uploading',
      });
    },
    /**
     * emit数据
     */
    emitData() {
      console.log(this.formData.tableData);
      this.$emit('input', this.formData.tableData);
    },
    /**
     * 设置标题
     */
    setAllTitle(v) {
      if (!this.selectList.length) {
        this.$message({
          type: 'warning',
          message: '请先选择视频',
        });
        return;
      }
      this.formData.tableData.forEach((item) => {
        if (this.selectList.includes(item.uid)) {
          item.videoTitle = v;
        }
      });
      this.emitData();
    },
    /**
     * 对外提供的验证
     */
    validate() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    value() {
      this.formData.tableData = this.value;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table .cell {
  overflow: visible;
}
</style>
