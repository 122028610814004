<template>
  <div>
    <el-drawer
      title="上传视频"
      size="1200px"
      :visible="uploadVideoYoutubeShow"
      append-to-body
      @close="close"
      @opened="openInit"
    >
      <div class="flex flex-col h-[94%]">
        <el-form
          ref="form"
          label-width="100px"
          class="youtubeForm youtubeVideos"
          :model="accountParams"
          :inline="true"
        >
          <YouTubeAccount
            ref="youtubeAccount"
            v-model="accountParams"
            :disabled="accountDisable"
            @change="changeChannel"
          />
          <el-form-item
            label=""
            class="mb-0"
          >
            <el-upload
              class="upload-demo"
              action="#"
              accept=".mp4"
              list-type="picture-card"
              multiple
              :on-change="selectVideo"
              :file-list="fileList"
              :auto-upload="false"
              :show-file-list="false"
            >
              <el-button type="primary">本地上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="p-2 mt-4 bg-slate-100 rounded-lg">
          <el-form
            ref="form"
            class="youtubeVideos"
            label-width="120px"
            :model="multiTitle"
          >
            <el-form-item
              label="批量设置标题"
              class="w-11/12 inline-block mr-4 mb-0"
            >
              <el-input
                placeholder="输入标题"
                class="w-full"
                v-model="multiTitle.title"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              class="inline-block"
              @click="setAllTitle"
              >应用</el-button
            >
          </el-form>
        </div>
        <div class="flex-1 mt-2 overflow-auto">
          <SelectVideoList
            ref="videoList"
            v-model="fileList"
            :title="multiTitle.title"
            @uploadFile="uploadFile"
          />
        </div>
      </div>
      <div class="absolute right-3 bottom-5">
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import YouTubeAccount from '@/views/google/components/YouTubeAccount/index.vue';
import SelectVideoList from '@/views/google/components/SelectVideoList/index.vue';
import getVideoMes from '@adCommonComponent/utils/getVideoMes.js';
import { uploadSingle } from '@/api/common.js';
export default {
  components: { YouTubeAccount, SelectVideoList },
  props: {
    // 弹窗显示隐藏
    uploadVideoYoutubeShow: {
      type: Boolean,
      default: false,
    },
    // youtube账号
    youtubeChannel: {
      type: Object,
      default: () => {},
    },
    // 账号是否可选
    accountDisable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      //youtube账号信息
      accountParams: {
        channelId: null,
      },
      multiTitle: { title: '' }, //批量设置标题
      fileList: [], //上传的视频列表
    };
  },
  methods: {
    /**
     * 打开弹窗初始化
     */
    openInit() {
      this.$refs.youtubeAccount.getChannel();
      this.$nextTick(() => {
        this.accountParams.channelId = this.youtubeChannel?.id;
      });
      this.fileList = [];
    },
    /**
     * 选择频道
     */
    changeChannel(v) {
      this.accountParams.channelId = v;
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit('update:uploadVideoYoutubeShow', false);
    },
    /**
     * 点击确定
     */
    sure() {
      if (!this.accountParams.channelId) {
        this.$message({
          type: 'warning',
          message: '请选择频道上传视频',
        });
        return;
      }
      this.$refs.videoList.validate().then(() => {
        // 导出，选择的视频文件列表
        this.$emit(
          'updateValue',
          this.fileList.map((v) => {
            return {
              youtubeChannelId: this.accountParams.channelId,
              videoTitle: v.videoTitle,
              id: v.id,
              ossFileUrl: v.url,
              videoName: v.name,
            };
          }),
        );
        this.close();
      });
    },
    /**
     * 批量设置标题
     */
    setAllTitle() {
      this.$refs.videoList.setAllTitle(this.multiTitle.title);
    },
    /**
     * 校验视频大小
     * （横向 16:9、纵向 9:16、方形 1:1)
     */
    validateSize(width, height) {
      //图片宽高比
      const ratio = width / height;
      const transverseRatio = 16 / 9; //横向
      const transverse1Ratio = 4 / 3; //横向
      const squareRatio = 1; //方形
      const portraitRatio = 9 / 16; //纵向
      const isTransverse = ratio === transverseRatio;
      const isSquare = ratio === squareRatio;
      const isPortrait = ratio === portraitRatio;
      const isTransverse1 = ratio === transverse1Ratio;
      //  false 代表三个比例都不符合
      return isTransverse || isSquare || isPortrait || isTransverse1;
    },
    /**
     * 校验视频格式，大小，比例
     */
    validateVideo(file) {
      return new Promise((resolve, reject) => {
        const raw = file.raw;
        const videoType = ['video/mp4'];
        const fileSize = raw.size / 1024 / 1024;
        // 格式限制
        if (videoType.includes(raw.type)) {
          // 大小限制
          //   const isLt5MB = fileSize <= 5;
          //   if (!isLt5MB) {
          //     return reject('文件限制在5MB以内');
          //   }
          getVideoMes(file)
            .then((res) => {
              const { duration, videoWidth, videoHeight } = res;
              if (!this.validateSize(videoWidth, videoHeight)) {
                return reject('请选择16:9 / 4:3 / 1:1 / 9:16的视频');
              }
              if (duration < 10 && duration > 60) {
                return reject('请上传时长在10s以上60s以内的视频');
              }
              resolve();
            })
            .catch((err) => {
              return reject('文件加载失败');
            });
        } else {
          reject('文件格式不正确');
        }
      });
    },
    /**
     * 选择文件
     */
    selectVideo(file, fileList) {
      this.validateVideo(file)
        .then(() => {
          this.fileList.push({
            ...file,
            status: 'uploading',
            videoTitle: '',
            blobUrl: file.url,
          });
          this.uploadFile(file);
        })
        .catch((err) => {
          this.$message.error(err);
          //   let index = this.fileList.findIndex((v) => v.uid == file.uid);
          //   this.fileList.splice(index, 1);
        });
    },
    // 截取视频名称
    spliceFileName(str) {
      let lastIndex = str.lastIndexOf('.');
      return str.substring(0, lastIndex).slice(0, 100); //
    },
    // 上传视频到oss服务器
    uploadFile(file) {
      let index = this.fileList.findIndex((v) => v.uid === file.uid);
      console.log(this.fileList[index]);
      this.$set(this.fileList, index, {
        ...this.fileList[index],
        status: 'uploading',
        videoTitle: this.spliceFileName(this.fileList[index].name),
      });
      uploadSingle(file)
        .then((res) => {
          let index = this.fileList.findIndex((v) => v.uid === file.uid); //有可能上传的过程中删掉视频，所以这里重新取index
          this.$set(this.fileList, index, {
            ...this.fileList[index],
            status: 'success',
            url: res.data.url,
            id: res.data.id,
          });
        })
        .catch((err) => {
          this.$set(this.fileList, index, { ...this.fileList[index], status: 'error' });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: auto !important;
  height: auto !important;
  border: none;
  line-height: 1;
}
::v-deep .el-drawer__body {
  position: relative;
  padding: 20px;
}
</style>
<style lang="scss">
// 解决输入框受外部表单验证的影响
.youtubeVideos .el-input__inner {
  border-color: #dcdfe6 !important; /* 取消红色边框 */
  box-shadow: none !important; /* 取消红色阴影 */
}
</style>
